
  import { computed, defineComponent, onMounted, provide, ref, shallowRef, watch } from "vue";
  import { useStore } from "vuex";

  import { SpotifyWebApi } from "spotify-web-api-ts";
  import { PrivateUser } from "spotify-web-api-ts/types/types/SpotifyObjects";

  import LastFm from "@toplast/lastfm";

  import OctocatCorner from "@/components/OctocatCorner.vue";

  import { LastfmApiKey, SpotifyApiKey, SpotifyCurrentUserKey } from "@/data/injections";

  export default defineComponent({
    components: { OctocatCorner },

    setup() {
      const store = useStore();

      // SPOTIFY AUTH

      // TODO: make this a build environment variable
      const spotifyClientId = "6bebebaf68e407eab01ea7ca182a81a";

      const spotifyToken = computed(() => store.state.spotifyAccessToken);

      onMounted(() => store.dispatch("loadStoredState"));

      const spotifyApi = shallowRef(new SpotifyWebApi({ clientId: spotifyClientId }));
      const spotifyCurrentUser = ref<PrivateUser | null>(null);

      const fetchSpotifyState = (api: SpotifyWebApi) => {
        api.users
          .getMe()
          .then((user) => (spotifyCurrentUser.value = user))
          .catch((e) => {
            store.commit("setSpotifyToken", null);
            spotifyCurrentUser.value = null;
            console.error(e);
          });
      };

      // TODO: put state in Vuex store, should remove the need for this
      // fetchState(spotifyApi.value);

      watch(spotifyToken, (newValue) => {
        console.log("token changed to", newValue);

        if (newValue) {
          const api = new SpotifyWebApi({
            clientId: spotifyClientId,
            accessToken: newValue,
          });

          fetchSpotifyState(api);

          spotifyApi.value = api;
        } else {
          spotifyApi.value = new SpotifyWebApi({ clientId: spotifyClientId });
          spotifyCurrentUser.value = null;
        }
      });

      provide(SpotifyApiKey, spotifyApi);
      provide(SpotifyCurrentUserKey, spotifyCurrentUser);

      // LAST.FM "AUTH"

      const lastfmClientId =
        process.env.NODE_ENV === "production" ? "0300c01497ac7540c65f931b3baf50c9" : "f5b90182eeaa4dafa6ed6293327fe3d2";

      const lastfmApi = shallowRef(new LastFm(lastfmClientId));

      // TODO: last.fm "auth"
      const lastfmCurrentUser = computed(() => store.state.lastfmUsername);

      provide(LastfmApiKey, lastfmApi);

      return {
        authenticatedServices: computed(() => {
          const services = [];

          if (spotifyCurrentUser.value) services.push("Spotify");
          if (lastfmCurrentUser.value) services.push("Last.fm");

          return services;
        }),

        spotifyLogin: () => store.dispatch("getNewSpotifyToken"),
        spotifyLogout: () => store.commit("setSpotifyToken", null),
        spotifyCurrentUser,
        spotifyUsername: computed(() => spotifyCurrentUser.value?.display_name),

        // TODO: last.fm login
        lastfmLogin: () => {
          const username = prompt("Enter Last.fm username:");
          if (username != null) store.commit("setLastfmUsername", username);
        },
        lastfmCurrentUser,
      };
    },
  });
