
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "OctocatCorner",
    components: {},

    setup() {
      return {};
    },
  });
