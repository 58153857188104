import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-55c40db0")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "chart-wrapper"
}
const _hoisted_4 = {
  key: 0,
  class: "loading"
}
const _hoisted_5 = {
  key: 3,
  class: "warn"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.lastfmUser)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, "Top artists for " + _toDisplayString(_ctx.lastfmUser), 1))
      : (_openBlock(), _createElementBlock("h2", _hoisted_2, "Top artists")),
    (_ctx.lastfmUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("canvas", {
            id: "artist-chart",
            class: _normalizeClass({ hidden: !_ctx.data })
          }, null, 2),
          (!_ctx.data)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Loading... (" + _toDisplayString(_ctx.loadProgress) + "/" + _toDisplayString(_ctx.totalWeeks) + ")", 1))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_5, "You haven't set your Last.fm username."))
  ], 64))
}