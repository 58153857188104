
  import { defineComponent } from "vue";

  import { SimplifiedPlaylist } from "spotify-web-api-ts/types/types/SpotifyObjects";

  export default defineComponent({
    name: "PlaylistCard",
    props: {
      source: {
        type: Object as () => SimplifiedPlaylist,
        required: true,
      },
    },

    setup() {
      void 0;
    },
  });
