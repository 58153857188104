
  import { defineComponent, PropType } from "vue";

  import { PlaylistItem } from "spotify-web-api-ts/types/types/SpotifyObjects";

  export default defineComponent({
    name: "TrackList",
    props: {
      tracks: {
        type: Array as PropType<PlaylistItem[]>,
        required: true,
      },
    },

    setup() {
      void 0;
    },
  });
