import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3c435780")
const _hoisted_1 = { class: "playlist-card" }
const _hoisted_2 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.source.images[0]?.url,
      alt: ""
    }, null, 8, _hoisted_2),
    _createTextVNode(" " + _toDisplayString(_ctx.source.name), 1)
  ]))
}