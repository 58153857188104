import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-40065e22")
const _hoisted_1 = { class: "tracklist-container" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "right" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tracks, (track, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "tracklist-track"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: track.track.album.images[0]?.url,
            alt: ""
          }, null, 8, _hoisted_3),
          _createTextVNode(" " + _toDisplayString(track.track.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(track.track.album.artists.map((a) => a.name).join(", ")), 1)
      ]))
    }), 128))
  ]))
}