
  import { computed, defineComponent, inject } from "vue";

  import { SpotifyCurrentUserKey } from "@/data/injections";
  import { useStore } from "vuex";

  export default defineComponent({
    name: "Home",

    setup() {
      const store = useStore();

      const spotifyUser = inject(SpotifyCurrentUserKey);
      if (!spotifyUser) throw new Error("No SpotifyCurrentUser injected");

      const lastfmUser = computed(() => store.state.lastfmUsername);

      return {
        spotifyUser,
        lastfmUser,
      };
    },
  });
